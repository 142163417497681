
import { Component, Vue } from 'vue-property-decorator'
import echarts from 'echarts'

let chart: echarts.ECharts
@Component({
  name: 'Analyse'
})
export default class Analyse extends Vue {
  private activeName = 'week'
  private info = {
    avgUpPer: '',
    forecastData: '',
    realData: ''
  }

  get projectId () {
    return this.$route.params.projectId
  }

  created () {
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  resize () {
    chart && chart.resize()
  }

  handleClick () {
    this.getData()
  }

  getData (): void {
    this.$axios.get(this.$apis.waterMeter.selectWaterForecast, {
      projectId: this.projectId,
      type: this.activeName === 'week' ? '0' : '1'
    }).then(res => {
      this.info = {
        avgUpPer: res.avgUpPer,
        forecastData: res.forecastData,
        realData: res.realData
      }
      this.drawCharts(res.xCollectTimes || [], res.yDatas || [], res.yUpPers || [])
    })
  }

  // 折线图
  drawCharts (xData: Array<string>, yData1: Array<string>, yData2: Array<string>) {
    this.$nextTick(() => {
      const len = yData1.length
      const ydataforecast = len > 0 ? new Array(len).fill('').fill(this.info.forecastData, len - 1, len) : [this.info.forecastData]
      const option: any = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params: Array<{ name: string; seriesName: string; data: any }>) {
            let str = ''
            params.forEach((item, i) => {
              if (i === 0) {
                str += `${item.name}<br/>${item.seriesName}：<span>${item.data}</span>m³<br/>`
              } else if (i === 2) {
                str += `${item.seriesName}：<span>${item.data}</span>%<br/>`
              } else {
                str += item.data ? `${item.seriesName}：<span>${item.data}</span>m³<br/>` : ''
              }
            })
            return str
          }
        },
        grid: {
          top: 30,
          left: 0,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        backgroundColor: 'transparent',
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: xData
        },
        yAxis: [{
          name: '水量',
          type: 'value',
          offset: -2,
          axisLine: {
            show: false
          },
          axisLabel: {
            interval: 0,
            formatter: '{value} m³'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }, {
          name: '增长率',
          type: 'value',
          offset: -2,
          axisLine: {
            show: false
          },
          axisLabel: {
            interval: 0,
            formatter: '{value} %'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }],
        series: [{
          name: '耗水量',
          type: 'bar',
          yAxisIndex: 0,
          barGap: '0',
          color: '#2C8EFF',
          barMaxWidth: 32,
          itemStyle: {
            shadowBlur: 6,
            shadowColor: 'rgba(44, 142, 255, 0.2)',
            shadowOffsetX: 6
          },
          data: yData1
        }, {
          name: '预测耗水量',
          type: 'bar',
          yAxisIndex: 0,
          barGap: '0',
          color: 'rgba(125, 39, 255, 0.4)',
          barMaxWidth: 32,
          itemStyle: {
            borderWidth: 1,
            borderType: 'dashed',
            borderColor: 'rgba(125, 39, 255, 1)'
          },
          data: ydataforecast
        }, {
          name: '增长率',
          type: 'line',
          yAxisIndex: 1,
          color: '#36CBCB',
          symbolSize: 10,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(54, 203, 203, 0.2)',
            shadowOffsetY: 20
          },
          data: yData2
        }]
      }
      chart = echarts.init((this.$refs.chart as any))
      chart.setOption(option)
    })
  }
}
